var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoring-system"},[_c('div',{staticClass:"w-full flex justify-end"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.handleClose(); _vm.popupIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('rank_system_add_rank'))+" ")])],1),(_vm.popupIsOpen)?_c('a-modal',{attrs:{"title":_vm.$t('rank_system_new_rank_params'),"centered":"","after-close":_vm.resetRankModel,"footer":null},model:{value:(_vm.popupIsOpen),callback:function ($$v) {_vm.popupIsOpen=$$v},expression:"popupIsOpen"}},[_c('div',{staticClass:"flex flex-col"},[_c('text-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.rankModel,
            'key': 'title',
            'prefix': 'scoring_system_',
            'validation': 'required'
          }
        }}}),(_vm.useXp && _vm.ranks.length)?[_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.rankModel,
              'key': 'xp',
              'prefix': 'scoring_system_',
              'validation': 'required'
            }
          }}}),(_vm.newRankRep && _vm.newRankRep.invalid)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.newRankRep.message)+" ")]):_vm._e()]:_vm._e(),(_vm.useReputation && _vm.ranks.length)?[_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.rankModel,
              'key': 'reputation',
              'prefix': 'scoring_system_',
              'validation': 'required'
            }
          }}}),(_vm.newRankXp && _vm.newRankXp.invalid)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.newRankXp.message)+" ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"flex flex-row justify-end"},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.newRankCreateAvailable || !_vm.rankModel.title},on:{"click":_vm.addNewRank}},[_vm._v(" "+_vm._s(_vm.$t('scoring_system_' + 'add_item'))+" ")])],1)],2)]):_vm._e(),_c('a-divider'),_c('a-table',{attrs:{"data-source":_vm.chatRanks,"pagination":false,"scroll":{ x: true }}},[_c('a-table-column',{key:"index",scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index + 1)+". ")]}}])}),_c('a-table-column',{key:"title",attrs:{"data-index":"title"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [(_vm.editKey === record.key)?_c('text-input',{staticClass:"record_field",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.rankModel,
              'key': 'title',
              'titleVisible': false
            },
          }}}):_c('b',[_vm._v(" "+_vm._s(text)+" ")])]}}])},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('scoring_system_title'))+" ")])]),(_vm.useXp)?_c('a-table-column',{key:"xp",attrs:{"data-index":"xp"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [(text === null)?_c('span',[_vm._v(" - ")]):[(_vm.editKey === record.key)?_c('number-input',{staticClass:"record_field",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.rankModel,
                'key': 'xp',
                'titleVisible': false,
                'inline': false,
              }
            }}}):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]]}}],null,false,1727714738)},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('scoring_system_xp'))+" ")])]):_vm._e(),(_vm.useReputation)?_c('a-table-column',{key:"reputation",attrs:{"data-index":"reputation"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [(text === null)?_c('span',[_vm._v(" - ")]):[(_vm.editKey === record.key)?_c('number-input',{staticClass:"record_field",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.rankModel,
                'key': 'reputation',
                'titleVisible': false,
                'inline': false,
              }
            }}}):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]]}}],null,false,1096529665)},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('scoring_system_reputation'))+" ")])]):_vm._e(),_c('a-table-column',{key:"actions",scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('div',{staticClass:"flex"},[(_vm.editKey === record.key)?[_c('a-button',{staticClass:"btn-success ml-2",attrs:{"icon":"check","disabled":_vm.editRankInvalid},on:{"click":_vm.updateRecord}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"danger","icon":"close"},on:{"click":_vm.handleClose}})]:[_c('a-button',{attrs:{"type":"primary","icon":"setting"},on:{"click":function($event){return _vm.setRecordToEdit(record)}}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"danger","icon":"delete"},on:{"click":function($event){return _vm.deleteRecord(record.key)}}})]],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }